export const CARDS: any = [
    {
        type: 'maestro',
        cvvLength: [3],
    }, {
        type: 'forbrugsforeningen',
        cvvLength: [3],
    }, {
        type: 'dankort',
        cvvLength: [3],
    }, {
        type: 'visa',
        cvvLength: [3],
    }, {
        type: 'mastercard',
        cvvLength: [3],
    }, {
        type: 'amex',
        cvvLength: [3, 4],
    }, {
        type: 'dinersclub',
        cvvLength: [3],
    }, {
        type: 'discover',
        cvvLength: [3],
    }, {
        type: 'unionpay',
        cvvLength: [3],
    }, {
        type: 'jcb',
        cvvLength: [3],
    },
];