import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
    selector: '[appCheckScroll]'
})
export class CheckScrollDirective implements OnInit, AfterViewInit {
    @Input() checkLoad: boolean;
    @Input() scrollElement: HTMLDivElement;

    @Output() scrollEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.scrollElement && this.scrollElement.offsetHeight) {
            if (this.scrollElement.offsetHeight < 360) {
                this.scrollEmit.emit(true);
            }
        }
    }

    @HostListener('scroll', ['$event'])

    onScroll($event): void {
        $event.preventDefault();
        const element = $event.srcElement;
        const checkScroll = (element.offsetHeight + element.scrollTop >= element.scrollHeight);
        if (this.checkLoad && checkScroll) {
            this.scrollEmit.emit(true);
        }
    }

}
